import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function About(){
    return(<div>
  <div className="site-breadcrumb">
    <div className="container">
      {/* <a href="#"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-right"></i> */}
      {/* <span>About Us</span> */}
    </div>
  </div>
  {/* Breadcrumb section end */}
  {/* About section */}
  <section className="about-section spad pt-0">
    <div className="container">
      <div className="section-title text-center">
        <h3>WELCOME TO LILOOP</h3>
        <p>BRINGING BATTERIES BACK TO LIFE</p>
      </div>
      <div className="row">
        <div className="col-lg-6 about-text">
          <h5>About us</h5>
          <p style={{ fontFamily: '"Poppins"' }}>
            Incepted from IIT Bhubaneswar, Liloop focussed on the
            commercialization of cutting-edge energy storage technology and
            complement the fight against climate change. Thus, making the Earth
            a better place to live in and flourish.
          </p>
          <h5 className="pt-4">Our history</h5>
          {/* <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
           */}{" "}
          <ul className="about-list">
            <li>
              <i
                className="fa fa-check-square-o"
                style={{ fontFamily: '"Poppins"' }}
              />
              Liloop is a result of several years of research work carried out
              at the Green Energy laboratory at IIT Bhubaneswar
            </li>
            <li>
              <i
                className="fa fa-check-square-o"
                style={{ fontFamily: '"Poppins"' }}
              />{" "}
              Most businesses know what and how, but we founded Liloop based on
              WHY?
            </li>
            <li>
              <i
                className="fa fa-check-square-o"
                style={{ fontFamily: '"Poppins"' }}
              />
              Our why is ensuring that our future generation gets the
              environmental resources they deserve. And the most important one
              is a clean and sustainable atmosphere.
            </li>
            <li>
              <i
                className="fa fa-check-square-o"
                style={{ fontFamily: '"Poppins"' }}
              />{" "}
              We are a technology-driven company striving for the transition of
              fossil fuel to clean energy, using more reliable and affordable
              tools based on our advanced and state of art energy storage
              solutions.
            </li>
          </ul>
        </div>
        <div className="col-lg-6 pt-5 pt-lg-0">
          {/* <img src="img/poster4.png" alt="poster4" /> */}
        </div>
      </div>
    </div>
  </section>
  {/* About section end*/}
  {/* Testimonial section  */}
  <section className="testimonial-section spad">
    <div className="container">
      <div className="testimonial-slider">
          <OwlCarousel items={1} autoplay  nav>
        <div className="ts-item">
          <div className="row">
            <div className="col-md-3">
              <div
                className="ts-author-pic set-bg"
                style={{ backgroundImage: "url(/img/member/soobhankar.jpg)" }}
                data-setbg="img/member/soobhankar.jpg"
              />
            </div>
            <div className="col-md-9 ts-text">
              <p style={{ fontFamily: '"Poppins"' }}>
                “ Currently Dr. Pati is working as Assistant Professor at IIT
                Bhubaneswar. He is responsible for the development of new and
                advanced materials for Lithium batteries and Fuel-cell
                technology. He holds 3 patents and several research publications
                in the field of electrochemistry and energy storage. ”
              </p>
              <h5>Soobhankar Pati, Ph.D</h5>
              <span style={{ fontFamily: '"Poppins"' }}>
                Co-founder, Director, Research and Development
              </span>
            </div>
          </div>
        </div>
        <div className="ts-item">
          <div className="row">
            <div className="col-md-3">
              <div
                className="ts-author-pic set-bg"
                style={{ backgroundImage: "url(/img/member/ashwani.jpg)" }}
                data-setbg="img/member/ashwani.jpg"
              />
            </div>
            <div className="col-md-9 ts-text">
              <p style={{ fontFamily: '"Poppins"' }}>
                “ 5 Years experience in the field of the solar industry.
                Mechanical engineer cum battery innovator. Visionary and expert
                in commercializing ideas. ”
              </p>
              <h5 style={{ fontFamily: '"Poppins"' }}>Ashwani Pandey</h5>
              <span style={{ fontFamily: '"Poppins"' }}>CEO, Co-founder</span>
            </div>
          </div>
        </div>
        <div className="ts-item">
          <div className="row">
            <div className="col-md-3">
              <div
                className="ts-author-pic set-bg"
                style={{ backgroundImage: "url(/img/member/uttam.png)" }}
                data-setbg="img/member/uttam.png"
              />
            </div>
            <div className="col-md-9 ts-text">
              <p style={{ fontFamily: '"Poppins"' }}>
                “ Experience in the field related to fuel-cell technology
                research. Expertise in the field of electrochemical engineering.
                Published several research articles ”
              </p>
              <h5 style={{ fontFamily: '"Poppins"' }}>Uttam K. Chanda, PhD</h5>
              <span style={{ fontFamily: '"Poppins"' }}>
                Head, Product Development
              </span>
            </div>
          </div>
        </div>
        <div className="ts-item">
          <div className="row">
            <div className="col-md-3">
              <div
                className="ts-author-pic set-bg"
                style={{ backgroundImage: "url(/img/member/anubhav.jpg)" }}
                data-setbg="img/member/anubhav.jpg"
              />
            </div>
            <div className="col-md-9 ts-text">
              <p style={{ fontFamily: '"Poppins"' }}>
                “Seasoned entrepreneur with more than 10 years of business
                experience. ”
              </p>
              <h5 style={{ fontFamily: '"Poppins"' }}>Anubhav Jain</h5>
              <span style={{ fontFamily: '"Poppins"' }}>
                Director, Strategy and growth
              </span>
            </div>
          </div>
        </div>
        </OwlCarousel>
      </div>
    </div>
  </section>
  {/* Testimonial section end */}
  <section className="section section-default mt-none mb-none">
    <div className="container">
      <center>
        <h2 className="mb-sm" style={{ color: "#5FCF80" }}>
          Our <strong>Partners</strong>
        </h2>
      </center>
      <br />
      <br />
      <strong>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div
              className="square-holder"
              style={{
                padding: 30,
                border: "1px solid #cecece",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
                backgroundColor: "#f1f1f1",
                height: 262
              }}
            >
              <img
                alt = "logo2"
                src="img/blog/logo2.png"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transition: "all 0.3s"
                }}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div
              className="square-holder"
              style={{
                padding: 30,
                border: "1px solid #cecece",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
                backgroundColor: "#f1f1f1",
                height: 262
              }}
            >
              <img
                alt = "logo1"
                src="img/blog/logo1.jpg"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transition: "all 0.3s"
                }}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div
              className="square-holder"
              style={{
                padding: 30,
                border: "1px solid #cecece",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
                backgroundColor: "#f1f1f1",
                height: 262
              }}
            >
              <img
                alt="logo3"
                src="img/blog/logo3.png"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transition: "all 0.3s"
                }}
              />
            </div>
          </div>
        </div>
      </strong>
    </div>
  </section>
  {/* Newsletter section */}
  <section className="newsletter-section">
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-lg-7">
          <div className="section-title mb-md-0">
            <h3>
              GET UPTO{" "}
              <span
                style={{
                  color: "red",
                  fontWeight: 50,
                  fontFamily: '"Poppins", sans-serif'
                }}
              >
                30%{" "}
              </span>{" "}
              MONEY BACK
            </h3>
            <h4 style={{ color: "#063970", fontFamily: "all" }}>
              On replacement of your any electric vehicle battery
            </h4>
          </div>
        </div>
        <div className="col-md-7 col-lg-5" style={{ paddingTop: "3%" }}>
          <div className="site-btn">
            <a href="buyback.html" style={{ color: "#fff" }}>
              Know More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Newsletter section end */}

    </div>)
}
export default About;