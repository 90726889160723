function  Contact(){
    return(<div>
        <div style={{ marginTop: 50 }} className="section-title text-center">
          <h3>CONTACT US</h3>
          <p>WELCOME TO LILOOP</p>
        </div>
        {/* Courses section */}
        <section className="contact-page spad pt-0">
          <div className="container">
            <div className="map-section">
              <div className="contact-info-warp">
                <div className="contact-info">
                  <h4>Address</h4>
                  <p>SIKARIPUR , NIMPUR ,JAGATPUR , CUTTACK ,754021</p>
                </div>
                <div className="contact-info">
                  <h4>Phone</h4>
                  <p>(+91) 9454169474</p>
                </div>
                <div className="contact-info">
                  <h4>Email</h4>
                  <p>info@liloop.in</p>
                </div>
                <div className="contact-info">
                  <h4>Working time</h4>
                  <p>Monday - Friday: 10 AM - 06 PM</p>
                </div>
              </div>
              {/* Google map */}
              <div className="map" id="map-canvas">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3745.6587273220634!2d85.66899991442182!3d20.14828042262773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSTARTUP%20CELL%2C%20SAMANTAPURI%20CAMPUS%2C%20IIT%20BHUBANESWAR%2C%20ODISHA-%20751013!5e0!3m2!1sen!2sin!4v1620655021611!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
            <div className="contact-form spad pb-0">
              <div className="section-title text-center">
                <h3>GET IN TOUCH</h3>
                <p>Contact us for best deals and offer</p>
              </div>
              <form className="comment-form --contact">
                <div className="row">
                  <div className="col-lg-4">
                    <input type="text" placeholder="Your Name" />
                  </div>
                  <div className="col-lg-4">
                    <input type="text" placeholder="Your Email" />
                  </div>
                  <div className="col-lg-4">
                    <input type="text" placeholder="Subject" />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="Battery Specifications"
                      defaultValue={""}
                    />
                    <div className="text-center">
                      <button className="site-btn">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* Courses section end*/}
        <section className="call spad set-bg" style={{backgroundImage: "url(/img/call-bg.jpg)"}} data-setbg="img/call-bg.jpg">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="call__text">
                  <div
                    className="section-title"
                    style={{ textAlign: "left", marginBottom: 35 }}
                  >
                    <h2 style={{ color: "#f5a742", marginBottom: 12 }}>CAREERS</h2>
                    <p style={{ color: "#ffffff" }} />
                    <ul style={{ color: "#f2ede6" }}>
                      <li>
                        Freedom, Innovation and Environmental consciousness is in the
                        DNA of Liloop. If you feel coherent with these DNAs , you are
                        welcome to join our team.
                        <br />
                      </li>
                      <li>
                        We believe in power of collaboration And the unique Skill set
                        each individual brings on the table
                        <br />
                      </li>
                      <li>
                        At liloop you will team up with some of the best and brightest
                        minds to create innovative energy storage solutions.
                        <br />
                      </li>
                      <li>
                        Start working on climate Solutions Now! <br />
                      </li>
                      <p />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-6">
                <form action="#" className="call__form">
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Name"
                        style={{
                          width: "100%",
                          height: 48,
                          color: "#5C5C5C",
                          fontSize: 15,
                          paddingLeft: 20,
                          border: "none",
                          borderRadius: 2,
                          marginBottom: 20
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Email"
                        style={{
                          width: "100%",
                          height: 48,
                          color: "#5C5C5C",
                          fontSize: 15,
                          paddingLeft: 20,
                          border: "none",
                          borderRadius: 2,
                          marginBottom: 20
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Phone"
                        style={{
                          width: "100%",
                          height: 48,
                          color: "#5C5C5C",
                          fontSize: 15,
                          paddingLeft: 20,
                          border: "none",
                          borderRadius: 2,
                          marginBottom: 20
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <button type="submit" className="site-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* Newsletter section */}
        <section className="newsletter-section">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-7">
                <div className="section-title mb-md-0">
                  <h3>
                    GET UPTO{" "}
                    <span
                      style={{
                        color: "red",
                        fontWeight: 50,
                        fontFamily: '"Poppins", sans-serif'
                      }}
                    >
                      30%{" "}
                    </span>{" "}
                    MONEY BACK
                  </h3>
                  <h4
                    style={{ color: "#063970", fontFamily: '"Poppins", sans-serif' }}
                  >
                    On replacement of your any electric vehicle battery
                  </h4>
                </div>
              </div>
              <div className="col-md-7 col-lg-5" style={{ paddingTop: "3%" }}>
                <div className="site-btn">
                  <a href="buyback.html" style={{ color: "#fff" }}>
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Newsletter section end */}
        </div>
      )
}
export default Contact;