function Products(){
    return(<div>
        <div className="header-section text-center">
          <div className="container">
            <div className="text-center">
              <div
                className="wow bounceInDown"
                data-wow-offset={0}
                data-wow-delay="0.3s"
              ></div>
              <div
                className="wow bounceInDown"
                data-wow-offset={0}
                data-wow-delay="0.6s"
              >
                <h2 style={{ color: "#5FCF80" }}>STANDARD</h2>
              </div>
            </div>
          </div>
          <hr className="bottom-line" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="profile-wrapper wow fadeInRight" data-wow-delay="0.3s">
                <h3> E rickshaw 48V-100Ah</h3>
                <p />
                <ul>
                  <li style={{ textTransform: "capitalize" }}>
                    Compact size and less weight
                  </li>
                  <li style={{ textTransform: "capitalize" }}>Fast charging</li>
                  <li style={{ textTransform: "capitalize" }}>Higher backup</li>
                  <li style={{ textTransform: "capitalize" }}> longer life</li>
                  <li style={{ textTransform: "capitalize" }}>Maintenance free</li>
                  <li style={{ fontWeight: "bold", color: "grey" }}>
                    {" "}
                    3 Years Full warranty 20% Value Buyback while returning the used
                    Battery
                  </li>
                </ul>
                <p />
                <a
                  href="Files/product1.pdf"
                  target="_blank"
                  className="btn btn-common"
                >
                  <i className="icon-paper-clip" /> Download Specifications
                </a>
                <a href="contact.html" className="btn btn-danger">
                  <i className="icon-speech" /> Contact Me
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
                <img className="img-fluid" src="img/products_2.jpg" alt />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="about-profile">
                <ul className="admin-profile">
                  <li>
                    <span className="pro-title">Model name </span>{" "}
                    <span className="pro-detail">
                      Li-Loop Chalte Raho E-Rickshaw Battery
                    </span>
                  </li>
                  <li>
                    <span className="pro-title"> Chemistry </span>{" "}
                    <span className="pro-detail">LFP</span>
                  </li>
                  <li>
                    <span className="pro-title"> Capacity(Ah)</span>{" "}
                    <span className="pro-detail">100 Ah</span>
                  </li>
                  <li>
                    <span className="pro-title">
                      {" "}
                      Number of cells in series &amp; parallel
                    </span>{" "}
                    <span className="pro-detail">15 : 1</span>
                  </li>
                  <li>
                    <span className="pro-title"> Total Number of cell</span>{" "}
                    <span className="pro-detail">15</span>
                  </li>
                  <li>
                    <span className="pro-title"> Nominal Voltage</span>{" "}
                    <span className="pro-detail">48v</span>
                  </li>
                  <li>
                    <span className="pro-title"> KwH </span>{" "}
                    <span className="pro-detail">4800 Wh</span>
                  </li>
                  <li>
                    <span className="pro-title"> BMS Type </span>{" "}
                    <span className="pro-detail">Balancing Type (Passive)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="profile-wrapper" data-wow-delay="0.3s">
                <h3> E rickshaw 60V 100Ah</h3>
                <p />
                <ul>
                  <li style={{ textTransform: "capitalize" }}>
                    Compact size and less weight
                  </li>
                  <li style={{ textTransform: "capitalize" }}>Fast charging</li>
                  <li style={{ textTransform: "capitalize" }}>Higher backup</li>
                  <li style={{ textTransform: "capitalize" }}> longer life</li>
                  <li style={{ textTransform: "capitalize" }}>Maintenance free</li>
                  <li style={{ fontWeight: "bold", color: "grey" }}>
                    {" "}
                    3 Years Full warranty 20% Value Buyback while returning the used
                    Battery
                  </li>
                </ul>
                <p />
                <a
                  href="Files/product2.pdf"
                  target="_blank"
                  className="btn btn-common"
                >
                  <i className="icon-paper-clip" /> Download Specifications
                </a>
                <a href="contact.html" className="btn btn-danger">
                  <i className="icon-speech" /> Contact Me
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="img-thumb" data-wow-delay="0.3s">
                <img className="img-fluid" src="img/products_1.jpg" alt />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="about-profile">
                <ul className="admin-profile">
                  <li>
                    <span className="pro-title">Model name </span>{" "}
                    <span className="pro-detail">
                      Li-Loop Chalte Raho E-Rickshaw Battery
                    </span>
                  </li>
                  <li>
                    <span className="pro-title"> Chemistry </span>{" "}
                    <span className="pro-detail">LFP</span>
                  </li>
                  <li>
                    <span className="pro-title"> Capacity(Ah)</span>{" "}
                    <span className="pro-detail">100 Ah</span>
                  </li>
                  <li>
                    <span className="pro-title">
                      {" "}
                      Number of cells in series &amp; parallel
                    </span>{" "}
                    <span className="pro-detail">19 : 1</span>
                  </li>
                  <li>
                    <span className="pro-title"> Total Number of cell</span>{" "}
                    <span className="pro-detail">19</span>
                  </li>
                  <li>
                    <span className="pro-title"> Nominal Voltage</span>{" "}
                    <span className="pro-detail">60v</span>
                  </li>
                  <li>
                    <span className="pro-title"> KwH </span>{" "}
                    <span className="pro-detail">6000 Wh</span>
                  </li>
                  <li>
                    <span className="pro-title"> BMS Type </span>{" "}
                    <span className="pro-detail">Balancing Type (Passive)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="profile-wrapper" data-wow-delay="0.3s">
                <h3> E rickshaw 60V-130Ah</h3>
                <p />
                <ul>
                  <li style={{ textTransform: "capitalize" }}>
                    Compact size and less weight
                  </li>
                  <li style={{ textTransform: "capitalize" }}>Fast charging</li>
                  <li style={{ textTransform: "capitalize" }}>Higher backup</li>
                  <li style={{ textTransform: "capitalize" }}> longer life</li>
                  <li style={{ textTransform: "capitalize" }}>Maintenance free</li>
                  <li style={{ fontWeight: "bold", color: "grey" }}>
                    {" "}
                    3 Years Full warranty 20% Value Buyback while returning the used
                    Battery
                  </li>
                </ul>
                <p />
                <a
                  href="Files/product3.pdf"
                  target="_blank"
                  className="btn btn-common"
                >
                  <i className="icon-paper-clip" /> Download Specifications
                </a>
                <a href="contact.html" className="btn btn-danger">
                  <i className="icon-speech" /> Contact Me
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="img-thumb" data-wow-delay="0.3s">
                <img className="img-fluid" src="img/products_2.jpg" alt />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="about-profile">
                <ul className="admin-profile">
                  <li>
                    <span className="pro-title">Model name </span>{" "}
                    <span className="pro-detail">
                      Li-Loop Chalte Raho E-Rickshaw Battery
                    </span>
                  </li>
                  <li>
                    <span className="pro-title"> Chemistry </span>{" "}
                    <span className="pro-detail">LFP</span>
                  </li>
                  <li>
                    <span className="pro-title"> Capacity(Ah)</span>{" "}
                    <span className="pro-detail">130 Ah</span>
                  </li>
                  <li>
                    <span className="pro-title">
                      {" "}
                      Number of cells in series &amp; parallel
                    </span>{" "}
                    <span className="pro-detail">19 : 2</span>
                  </li>
                  <li>
                    <span className="pro-title"> Total Number of cell</span>{" "}
                    <span className="pro-detail">38</span>
                  </li>
                  <li>
                    <span className="pro-title"> Nominal Voltage</span>{" "}
                    <span className="pro-detail">60v</span>
                  </li>
                  <li>
                    <span className="pro-title"> KwH </span>{" "}
                    <span className="pro-detail">7800 Wh</span>
                  </li>
                  <li>
                    <span className="pro-title"> BMS Type </span>{" "}
                    <span className="pro-detail">Balancing Type (Passive)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="profile-wrapper" data-wow-delay="0.3s">
                <h3> Liloop HOPE UPS ,12V 100Ah</h3>
                <p />
                <ul>
                  <li style={{ textTransform: "capitalize" }}>
                    Compact size and less weight
                  </li>
                  <li style={{ textTransform: "capitalize" }}>Fast charging</li>
                  <li style={{ textTransform: "capitalize" }}>Higher backup</li>
                  <li style={{ textTransform: "capitalize" }}> longer life</li>
                  <li style={{ textTransform: "capitalize" }}>Maintenance free</li>
                  <li style={{ fontWeight: "bold", color: "grey" }}>
                    {" "}
                    5 Years Full warranty 20% Value Buyback while returning the used
                    Battery
                  </li>
                </ul>
                <p />
                <a
                  href="Files/product4.pdf"
                  target="_blank"
                  className="btn btn-common"
                >
                  <i className="icon-paper-clip" /> Download Specifications
                </a>
                <a href="contact.html" className="btn btn-danger">
                  <i className="icon-speech" /> Contact Me
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="img-thumb" data-wow-delay="0.3s">
                <img className="img-fluid" src="img/battery_ups.png" alt />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="about-profile">
                <ul className="admin-profile">
                  <li>
                    <span className="pro-title">Model name </span>{" "}
                    <span className="pro-detail">
                      Li-Loop UPS Tension Free Battery
                    </span>
                  </li>
                  <li>
                    <span className="pro-title"> Chemistry </span>{" "}
                    <span className="pro-detail">LFP</span>
                  </li>
                  <li>
                    <span className="pro-title"> Capacity(Ah)</span>{" "}
                    <span className="pro-detail">100 Ah</span>
                  </li>
                  <li>
                    <span className="pro-title">
                      {" "}
                      Number of cells in series &amp; parallel
                    </span>{" "}
                    <span className="pro-detail">4 : 1</span>
                  </li>
                  <li>
                    <span className="pro-title"> Total Number of cell</span>{" "}
                    <span className="pro-detail">4</span>
                  </li>
                  <li>
                    <span className="pro-title"> Nominal Voltage</span>{" "}
                    <span className="pro-detail">12v</span>
                  </li>
                  <li>
                    <span className="pro-title"> KwH </span>{" "}
                    <span className="pro-detail">1200 Wh</span>
                  </li>
                  <li>
                    <span className="pro-title"> BMS Type </span>{" "}
                    <span className="pro-detail">Balancing Type (Passive)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section
          className="enroll-section spad set-bg"
          style={{backgroundImage: "url(/img/pexels-dan-cristian-pădureț-1476321.jpg)"}}
          data-setbg="img/pexels-dan-cristian-pădureț-1476321.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-title text-white">
                  <h3>PROTOTYPE &amp; R&amp;D</h3>
                  <p>
                    We are in the process of Developing our own Lithium Pouch cell
                    with our patented Si Anode technology
                  </p>
                </div>
                <div className="enroll-list text-white">
                  <div className="enroll-list-item">
                    <span>1</span>
                    <h5>Using our patented nano-porous Silicon material.</h5>
                    {/* 	<p> Our quality product offers ultimate peace of mind and takes care of no holes being burnt into ppckets of users with there affordability </p> */}
                  </div>
                  <div className="enroll-list-item">
                    <span>2</span>
                    <h5>
                      We ensure high energy density coupled with fast charge rate
                      lithium battery.
                    </h5>
                    {/* 	<p>With long life of the battery packs, convenience takes a shot in the arm offering great value for money to the user
        </p> */}
                  </div>
                  <div className="enroll-list-item">
                    <span>3</span>
                    <h5>Making your fleet to reduce your dead charging hours.</h5>
                    {/* <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> */}
                  </div>
                  <div className="enroll-list-item">
                    <span>4</span>
                    <h5>
                      We use advanced mathematical tools to optimise the Cell design
                      parameters.
                    </h5>
                    {/* <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> */}
                  </div>
                  <div className="enroll-list-item">
                    <span>5</span>
                    <h5>
                      Parameters ensuring our cell to operate at maximum efficiency
                      even at higher Indian temperatures.
                    </h5>
                    {/* <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p>
                     */}{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 p-lg-0 p-4">
                <img src="img/IMAGE3.png" alt />
              </div>
            </div>
          </div>
        </section>
        <div style={{ margin: 10, padding: 50 }}>
          <center>
            <h2 className="mb-sm" style={{ color: "#5FCF80" }}>
              <strong>CUSTOMIZATION</strong>
            </h2>
          </center>
          <hr className="bottom-line" />
          <section
            className="work-shop"
            style={{ backgroundColor: "rgb(247, 247, 247)", padding: "60px 0px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="service-box ">
                    <div className="enroll-list text-white">
                      <div className="enroll-list-item">
                        <span>1</span>
                        <h5 style={{ color: "black" }}>
                          Every Fleet vehicle has its unique energy signature and thus
                          the unique battery requirement. We understand your pain of
                          finding out the battery pack specific to your fleet
                          requirement.
                        </h5>
                        {/* 	<p> Our quality product offers ultimate peace of mind and takes care of no holes being burnt into ppckets of users with there affordability </p> */}
                      </div>
                      <div className="enroll-list-item">
                        <span>2</span>
                        <h5 style={{ color: "black" }}>
                          We work with your team to understand your fleet energy
                          signature and design the pack to reduce the overall Battery
                          ownership cost and maximize your profit by providing longer
                          moving hours.
                        </h5>
                        {/* 	<p> Our quality product offers ultimate peace of mind and takes care of no holes being burnt into ppckets of users with there affordability </p> */}
                      </div>
                      {/* <p class="ser-text"style="	font-family: 'Poppins', sans-serif;opacity: 0.7;color: black; font-size:x-large">Every Fleet vehicle has its unique energy
      signature and thus the unique battery
      requirement. We understand your pain of
      finding out the battery pack specific to your
      fleet requirement. <br><br>
      We work with your team to understand your
      fleet energy signature and design the pack to
      reduce the overall Battery ownership cost and
      maximize your profit by providing longer
      moving hours.
              </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <img
                    style={{ boxShadow: "5px 5px 5px 5px rgba(0,0,0,0.1)" }}
                    src="img/IMAGE2.jpg"
                    alt
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div style="margin:10px;padding: 50px; ">
        <center>
      <h2 class="mb-sm" style="color: #5FCF80"><strong>PROTOTYPE & R&D</strong></h2></center>
      <hr class="bottom-line">
      <section class="work-shop" class="section-padding" style="background-color: rgb(247, 247, 247);padding: 60px 0px;">
          <div class="container">
        
      <div class="row">
          
      
      
          <div class="col-md-6 col-sm-6">
              <img style="box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.1)" src="img/IMAGE3.png" alt="">
        </div>  
      
      
      
          <div class="col-md-6 col-sm-6">
          <div class="service-box text-center" style="  background: #5FCF80;
          padding: 18px 30px 80px;
          box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.1);">
           
            <div class="icon-text">
              <h4 class="ser-text"style="font-size: 22px;font-family:all;opacity: 0.7;color: white; padding-top: 25px;">We are in the process of Developing our own
      Lithium Pouch cell with our patented Si
      Anode technology. <br><br><br>
      Using our patented nano-porous Silicon
      material, we ensure high energy density
      coupled with fast charge rate lithium battery,
      making your fleet to reduce your dead
      charging hours.<br><br><br>
      We use advanced mathematical tools to
      optimise the Cell design parameters ensuring
      our cell to operate at maximum efficiency
      even at higher Indian temperatures
              </h4>
            </div>
            <div style="padding-top: 25px">
          </div>
          </div>
        </div>
      
       
          
      
           
      
      </div>
          </div>
        </section>
      
      </div> */}
        {/* Newsletter section */}
        <section className="newsletter-section" style={{ paddingRight: 20 }}>
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-7">
                <div className="section-title mb-md-0">
                  <h3>
                    GET UPTO{" "}
                    <span
                      style={{
                        color: "red",
                        fontWeight: 50,
                        fontFamily: '"Poppins", sans-serif'
                      }}
                    >
                      30%{" "}
                    </span>{" "}
                    MONEY BACK
                  </h3>
                  <h4 style={{ color: "#063970", fontFamily: "all" }}>
                    On replacement of your any electric vehicle battery
                  </h4>
                </div>
              </div>
              <div className="col-md-7 col-lg-5" style={{ paddingTop: "3%" }}>
                <div className="site-btn">
                  <a href="buyback.html" style={{ color: "#fff" }}>
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      )
}
export default Products;