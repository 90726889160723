// import Header from "./homePage/header"
import Homepage from "./homePage/Homepage";
// import Services from "./Component/homePage/Services";
// import Portfolia from "./Component/homePage/Portfolia";
// import Membership from "./Component/membership";
// import Testimonial from "./Component/homePage/Testimonial";
// import Contact from "./Component/homePage/Contact";
// import Footer from "./homePage/Footer";
function Home() {
  return (
    <div>
    <Homepage />
</div>
  );
}

export default Home;
