import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Header from "./Component/header";
import Footer from "./Component/Footer";
import Home from "./Component/homeMain";
import Buy from "./Component/buyMain";
import About from "./Component/aboutMain"
import Products from "./Component/productsMain";
import Contact from "./Component/contactMain";
export default function App() {
    return (
        
        <BrowserRouter>
        <Header />
            <Routes>
                
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/buy" element={<Buy/>}/>
                <Route exact path="/about" element={<About />}/>
                <Route exact path="/products" element={<Products />}/>
                <Route exact path="/contact" element={<Contact />}/>
            </Routes>
            <Footer />
        </BrowserRouter>
        
    );
}
