import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Homepage(){
    return(<div>
        <section class="hero-section " style= {{paddingBottom : "50px"}}> 
        
      <div class="hero-slider">
      <OwlCarousel  items= "1" autoplay>
        <div class="hs-item set-bg item" style={{ backgroundImage: "url(/img/hero-slider/12.jpeg)" }} data-setbg="img/hero-slider/12.jpeg" >
            <div class="hs-text">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="container">
                                <h1 style= {{color : "#09f"}}>WELCOME <br/><span
                                        style= {{color: "#08cf47"}}> TO </span><br/></h1>
                                <div class="hero-slider">
                                    <div>
                                        <h2 class=""><span style= {{color : "#4ac4cf",fontSize : "52px"}}>Li-LOOP</span></h2>
                                    </div>


                                </div>
                            </div>
                           


                            <div class="site-btn"><a style = {{color: "#fff"}} href =  "#welcome">GET STARTED</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hs-item set-bg item" style={{ backgroundImage: "url(/img/hero-slider/12.jpeg)" }}  data-setbg="img/hero-slider/12.jpeg">
            <div class="hs-text">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="container">
                                <h1 class="" style= {{color: "white"}}>WELCOME <br/><span
                                        style= {{color : "#5FCF80"}}> TO </span><br/></h1>
                                <div class="hero-slider">
                                    
                                    <div>
                                        <h1 class=""><span style={{color : "orange"}}>Li-LOOP</span></h1>
                                    </div>


                                </div>
                            </div>


                            <div class="site-btn"><a style= {{color : "#fff"}} href =  "#welcome">GET STARTED</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </OwlCarousel>
    </div>
    
</section>

<section id="welcome"  class="enroll-section spad set-bg" style={{ backgroundImage: "url(/img/imagebg2.jpg)" }} data-setbg="img/imagebg2.jpg">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="section-title text-white">
                        <h3>Worried about high up-front cost lithium batteries for your vehicle?</h3>
                        <p>Get started with us to explore the exciting</p>
                    </div>
                    <div class="enroll-list text-white">
                        <div class="enroll-list-item">
                            <span>1</span>
                            <h5>Long Battery life.</h5>

                        </div>
                        <div class="enroll-list-item">
                            <span>2</span>
                            <h5>Reduce total owenership cost your vehicle.</h5>
                        </div>
                        <div class="enroll-list-item">
                            <span>3</span>
                            <h5>Use lithium batteries at 1.5 times price of lead-acid battery with 3 times the life.</h5>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1 p-lg-0 p-4">
                    <a href="product.html"><img src="img/products_2.jpg" alt="" style={{marginTop:"30%"}}/></a>
                    
                </div>
            </div>
        </div>
    </section>
       
 
    <section id="work-shop" class="section-padding" style = {{backgroundColor : "rgb(247, 247, 247)", padding: "60px 0px"}}>
        <div class="container">
            <div class="header-section text-center">
                <div class="container">
                    <div class="text-center">
                        <div class="wow bounceInDown" data-wow-offset="0" data-wow-delay="0.3s">

                        </div>
                        <div class="wow bounceInDown" data-wow-offset="0" data-wow-delay="0.6s">
                            <h2 style= {{color:"#5FCF80"}}>OUR SERVICES & PRODUCTS</h2>
                        </div>
                    </div>
                </div>


                <hr class="bottom-line"/>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="service-box text-center ourServicesBox1">
                        <div class="icon-box" style= {{Color:"#5FCF80",fontSize: "xx-large",padding:"20px 0px 0px"}}>
                            <i style= {{color:"#5FCF80"}} class="fa fa-calendar-plus-o color-green"></i>
                        </div>
                        <div class="icon-text">
                            <h4 class="ser-text"
                                style= {{fontSize:"22px",fontFamily:"Poppins",fontWeight:"bold",opacity:"0.7"}}>Li-ion
                                Battery Packs
                            </h4><br /><span style= {{color:"grey",fontFamily: "'Poppins',sans-serif"}}> Battery Packs which are superior
                                                 and smarter along with having a
                                                 longer battery life.</span>
                        </div>
                        <div style={{paddingTop: "25px"}}>
                            <div class="site-btn"><a href="buyback.html" style={{color:"#fff"}}>Know More</a></div>
                        </div>
                    </div>
                </div>


                <div class="col-md-4 col-sm-6">
                    <div class="service-box text-center ourServicesBox2">
                        <div class="icon-box" style= {{Color:"#5FCF80",fontSize: "xx-large",padding:"20px 0px 0px"}}>
                            <i style= {{color:"#5FCF80"}} class="fa fa-battery-half color-green"></i>
                        </div>
                        <div class="icon-text" >
                            <h4 class="ser-text"
                                 style= {{fontSize:"22px",fontFamily:"Poppins",fontWeight:"bold",opacity:"0.7"}}> Battery
                                Repurposing</h4><br /><span style= {{color:"grey",fontFamily: "'Poppins',sans-serif"}}>AI driven cell sorting for suitable second use 
</span>
                        </div>
                        <div style={{paddingTop:"40px"}}>
                            <div class="site-btn"><a href="buyback.html" style={{color:"#fff"}}>Know More</a></div>
                        </div>
                    </div>

                </div>


                <div class="col-md-4 col-sm-6">
                    <div class="service-box text-center  ourServicesBox3">
                        <div class="icon-box" style= {{Color:"#5FCF80",fontSize: "xx-large",padding:"20px 0px 0px"}}>
                            <i style= {{color:"#5FCF80"}} class="fa fa-battery-half color-green"></i>
                        </div>
                        <div class="icon-text">
                            <h4 class="ser-text"
                                 style= {{fontSize:"22px",fontFamily:"Poppins",fontWeight:"bold",opacity:"0.7"}}> Advanced
                                cell Manufacturing</h4><br /><span style= {{color:"grey",fontFamily: "'Poppins',sans-serif"}}>Advanced lithium Pouch cell with our patented Silicon Technology</span>
                        </div>
                        <div style={{paddingTop: "2px"}}>
                            <div class="site-btn"><a href="buyback.html" style={{color:"#fff"}}>Know More</a></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
    <section class="enroll-section spad set-bg" style={{ backgroundImage: "url(/img/battery-bg.jpg)" }} data-setbg="img/battery-bg.jpg">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="section-title text-white">
                        <h3>WHY CHOOSE US ?</h3>
                        <p>Get started with us to explore the exciting</p>
                    </div>
                    <div class="enroll-list text-white">
                        <div class="enroll-list-item">
                            <span>1</span>
                            <h5>More Affordable & Reliable.</h5>
                            {/* <!-- 	<p> Our quality product offers ultimate peace of mind and takes care of no holes being burnt into ppckets of users with there affordability </p> --> */}
                        </div>
                        <div class="enroll-list-item">
                            <span>2</span>
                            <h5>Long Battery cycle Life.</h5>
                            {/* <!-- 	<p>With long life of the battery packs, convenience takes a shot in the arm offering great value for money to the user */}
    {/* </p> --> */}
                        </div>
                        <div class="enroll-list-item">
                            <span>3</span>
                            <h5>End of life Buyback Value.</h5>
                            {/* <!-- <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> --> */}
                        </div>
                        <div class="enroll-list-item">
                            <span>4</span>
                            <h5>Customisation as per your Product Needs.</h5>
                            {/* <!-- <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> --> */}
                        </div>
                        <div class="enroll-list-item">
                            <span>5</span>
                            <h5>Strong Grip on R&D.</h5>
                            {/* <!-- <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> */}
                       </div>
                       <div class="enroll-list-item">
                            <span>6</span>
                            <h5>Leading the way in terms of Technology. </h5>
                            {/* <!-- <p>No maintenance of batteries means no worry for the users. Now use your products to their higest potential without any fuss of maintenance</p> */}
                       </div>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1 p-lg-0 p-4">
                   <a href="product.html"> <img src="img/poster_e-loader.png" alt=""/></a>
                </div>
            </div>
        </div>
    </section>
    <section class="courses-section spad">
        <div class="container">
            <div class="section-title text-center">
                <h2 style= {{color : "#5FCF80"}}>PRODUCTS</h2>
                <p>Building a better world, one course at a time</p>
                <hr class="bottom-line"/>
            </div>
            <div class="hero-slider">
            <OwlCarousel items= "1" autoplay>
                <div class="row">
                    {/* <!-- course item --> */}
                    <div class="col-lg-6 col-md-6 course-item">
                        <div class="course-thumb">
                           <a href="product.html"><img src="img/poster5.jpg" alt=""/></a> 
                            <div class="course-cat">
                                <span>STATIONARY BATTERY</span>
                            </div>
                        </div>

                    </div>
                    {/* <!-- course item --> */}
                    <div class="col-lg-6  col-md-6 course-item">
                        <div class="course-thumb">
                            <a href="product.html"><img src="img/poster_e-loader.png" alt=""/></a>
                            
                            <div class="course-cat">
                                <span>E-RICKSHAW/E-LOADER BATTERY</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    {/* <!-- course item --> */}
                    <div class="col-lg-6  col-md-6 course-item">
                        <div class="course-thumb">
                            <a href="product.html"><img src="img/poster_e-loader.png" alt=""/></a>
                            
                            <div class="course-cat">
                                <span>E-RICKSHAW/E-LOADER BATTERY</span>
                            </div>
                        </div>

                    </div>
                    {/* <!-- course item --> */}
                    <div class="col-lg-6 col-md-6 course-item">
                        <div class="course-thumb">
                            <a href="product.html"><img src="img/poster5.jpg" alt=""/></a>
                            
                            <div class="course-cat">
                                <span>STATIONARY BATTERY</span>
                            </div>
                        </div>

                    </div>
                </div>

                </OwlCarousel>
            </div>

        </div>
    </section>
    <section class="fact-section spad set-bg" style={{ backgroundImage: "url(/img/fact-bg.jpg)" }}  data-setbg="img/fact-bg.jpg">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3 fact">
                    <div class="fact-icon">
                        <i class="ti-crown"></i>
                    </div>
                    <div class="fact-text">
                        <h2>5+</h2>
                        <p>YEARS</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 fact">
                    <div class="fact-icon">
                        <i class="ti-package"></i>
                    </div>
                    <div class="fact-text">
                        <h2>50+</h2>
                        <p>BATTERIES DELIEVERED</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 fact">
                    <div class="fact-icon">
                        <i class="ti-reload"></i>
                    </div>
                    <div class="fact-text">
                        <h2>50+</h2>
                        <p>BATTERIES REPURPOSED</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 fact">
                    <div class="fact-icon">
                        <i class="ti-user"></i>
                    </div>
                    <div class="fact-text">
                        <h2>100+</h2>
                        <p>HAPPY CUSTOMERS</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="newsletter-section">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-lg-7">
                    <div class="section-title mb-md-0">
                        <h3>GET UPTO <span style={{color: "red" , fontWeight: "50",fontFamily: "Poppins"}}>30% </span> MONEY
                            BACK</h3>
                        <h4 style={{color:"#063970" ,fontFamily: "Poppins"}}>On replacement of your any electric
                            vehicle battery</h4><br/>
                            
                            
                    </div>
                </div>
                <div class="col-md-7 col-lg-5" style={{paddingTop:"3%"}}>
                    <div class="site-btn"><a href="/buy" style={{color:"#fff"}}>Know More</a></div>
                    
                </div>
            </div>
        </div>
    </section>
    </div>
)
}
export default Homepage;