import {Link}  from "react-router-dom";
function myFunction(){
    var x = document.getElementById("centered_nav");
        if (x.className === "rc_nav") {
            x.className += " responsive";
        } else {
            x.className = "rc_nav";
        }
}
function Header(){
    return(
    <div>
    <div id="rc_logo">
    <a href="index.html" title="Logo" ><img src="img/liloop.png" alt="" style={ {width : "100px",height:"50px"}} class="liloop_icon" /></a>
    </div>  
    
    <div className="rc_nav" id="centered_nav">

    <Link to={"/"}onClick={myFunction}>Home</Link>

    <Link  to={"/buy"}onClick={myFunction}>Buyback</Link>
    <Link  to={"/about"} onClick={myFunction}>About Us</Link>
    <Link  to={"/products"}onClick={myFunction}>Products</Link>
    <Link  to={"/contact" }onClick={myFunction}>Contact</Link>

    {/* <a href="about.html" title="About">About Us</a>
    <a href="product.html" title="About">Products</a>
    <a href="contact.html" title="Contact">Contact</a> */}
    <a href="javascript:void(0);" title="Menu" style= {{fontSize : "18px"}} className="icon" onClick={myFunction}>&#9776;</a>
    </div>
    </div>)
}
export default Header;
