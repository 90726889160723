function Footer(){
    return( <footer class="footer-section">
    <div class="container footer-top">
        <div class="row">
            {/* <!-- widget --> */}
            <div class="col-sm-6 col-lg-3 footer-widget">
                <div class="about-widget">
                    <img src="img/LOGO.png" alt=""/>
                    <p style= {{fontSize : "18px"}}>CELLARK POWERTECH PRIVATE LIMITED </p>
                    <p>MOST TRUSTED AND RELAIBLE WITH HIGHEST BATTERY LIFE CYCLE PROVIDING IN BHUBANESWAR </p>
                    <div class="social pt-1">
                        <a href=""><i class="fa fa-twitter-square"></i></a>
                        <a href=""><i class="fa fa-facebook-square"></i></a>
                        <a href=""><i class="fa fa-google-plus-square"></i></a>
                        <a href=""><i class="fa fa-linkedin-square"></i></a>
                        <a href=""><i class="fa fa-rss-square"></i></a>
                    </div>
                </div>
            </div>
            {/* <!-- widget --> */}
            <div class="col-sm-6 col-lg-3 footer-widget">
                <h6 class="fw-title">NAV LINK</h6>
                <div class="contact">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About us</a></li>
                        <li><a href="/product">Products</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>

                </div>
            </div>
            {/* <!-- widget --> */}

            {/* <!-- widget --> */}
            <div class="col-sm-6 col-lg-3 footer-widget">
                <h6 class="fw-title">CONTACT</h6>
                <ul class="contact">
                    <li><p><i class="fa fa-map-marker"></i> SIKARIPUR , NIMPUR ,JAGATPUR , CUTTACK ,754021</p></li>
                    <li><p><i class="fa fa-phone"></i> (+91) 9454169474</p></li>
                    <li><p><i class="fa fa-envelope"></i> info@liloop.in</p></li>
                    <li><p><i class="fa fa-clock-o"></i> Monday - Friday, 10:00AM - 06:00 PM</p></li>
                </ul>
            </div>
        </div>
    </div>
    {/* <!-- copyright --> */}
    <div class="copyright">
        <div class="container">
            <p>
              {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                Copyright &copy;<script>document.write(new Date().getFullYear());</script>
                All rights reserved | This is made with by <a href="https://technoboot.in/"
                                                              target="_blank">TECHNOBOOT</a>
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                </p>
        </div>
    </div>
</footer>)
}
export default Footer;