import React,{useEffect} from 'react'
import Aos from 'aos';
// import buyPage from '../buyMain';
import "aos/dist/aos.css";



 const BuyPage=()=> {
   useEffect(() => {
       Aos.init({duration:2000})
      
   }, [])
    return (
        <div>
            <section id="hero" class="d-flex justify-cntent-center " >
    <div id="heroCarousel" class="container carousel carousel-fade" data-ride="carousel">

    {/* style={"align-self: center;" */}


    </div>
</section> 
<section class="features">
            <div class="container">


                <div class="section-title">
                    <h2>Why Buyback ?</h2>

                </div>

                <div class="row" data-aos="fade-up">
                    <div class="col-md-5">
                        <img src="./img/thinking.jpg" class="img-fluid" data-aos="zoom-in" alt="" width="300" height="300" />
                        {/* <!--				<img src="../liloop-web/img/battery_ups.png"   class="img-fluid" alt="">--> */}
                    </div>
                    <div class="col-md-7 pt-4">
                        {/* <!--			<h3>Why you need to sell your old battery?</h3>--> */}
                        <p>
                            <ol style={{ fontSize: "large" }}>
                                <li> Disposing lithium batteries in unorganised way will impact the environmental negatively and
                                    dumping these waste will create huge toxic chemicals in the environment.
                                </li>

                                <li>Your Dead battery can generate wealth for you. You think, there is no value in your dead
                                    battery.No you are wrong
                                    even your dead electric scooter battery is worth Rs.4000 to 6000.Let us figure out the true
                                    value of your dead battery.
                                </li>

                                <li>Organised disposal will ensure smooth supply chain of lithium material for your new
                                    battery in future.
                                    be a responsible citizen and ensure the organised disposal of your dead battery.
                                </li>
                            </ol>
                        </p>

                    </div>
                </div>

                <div class="row" data-aos="fade-up">
                    <div class="col-md-5 order-1 order-md-2">
                        {/* <!--				<img src="img\ev_poster.png" class="img-fluid" data-aos="fade-left" alt="">--> */}
                        <img src="img/liloop.png" class="img-fluid" data-aos="zoom-in" alt="" />
                    </div>
                    <div class="col-md-7 pt-4">
                        {/* <!--			<h3>Why you need to sell your old battery?</h3>--> */}
                        <p>
                            <ol style={{ fontSize: "large" }}>
                                <li>We provide the true value of your dead battery after running some preliminary rapid tests at
                                    our
                                    centre.
                                </li>
                                <li> Easy payment within one week of receipt of the dead battery.</li>
                                <li>We can provide instant upfront cost reduction for the new battery if you provide the dead
                                    one to
                                    us.
                                </li>
                                <li> We can provide the home pickup of dead battery as well.</li>
                            </ol>

                        </p>

                    </div>
                </div>
            </div>
        </section>
        <section class="features">
            <div class="container">


                <div class="section-title">
                    <h2>HOW IT WORKS </h2>

                </div>

                <div class="row" data-aos="fade-up">
                    <div class="col-md-5">
                        <img src="img/j1.png" class="img-fluid" data-aos="flip-right" alt="" width="300" height="300" />
                    </div>
                    <div class="col-md-7 pt-4">
                        <h3>1:Contact us through email or WhatsApp</h3>
                        <p style={{ fontSize: "large" }}>Customer can contact us through email, whatsapp, facebook

                        </p>
                    </div>
                </div>

                <div class="row" data-aos="fade-up">
                    <div class="col-md-5 order-1 order-md-2">
                        <img src="img/j2.png" class="img-fluid" data-aos="zoom-in" alt="" width="500" height="500" />

                    </div>
                    <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="zoom-in">

                        <h3>2: Fill the form given</h3>
                        <p style={{ fontSize: "large" }}>
                            <a href="#hello" style={{ scrollBehavior: "smooth" }}>Click here to check the Form and Fill</a>
                        </p>


                    </div>
                </div>
                <div class="row" data-aos="fade-down">
                    <div class="col-md-5">
                        <img src="img/sipu.jpg" class="img-fluid" data-aos="flip-right" alt="" width="300" height="300" />
                    </div>
                    <div class="col-md-7 pt-4">
                        <h3> 3: We will get back to you</h3>
                        <p style={{ fontSize: "large" }}>We will contact you through email, Phone and various way as per user
                            Comfort
                            <br />Or else if Customer need Personal talk you can always come to your nearest office for a
                            chat.
                        </p>

                    </div>
                </div>
                <div class="row" data-aos="fade-down">
                    <div class="col-md-5 order-1 order-md-2">
                        <img src="img/jeeban.jpg" class="img-fluid" data-aos="flip-left" alt="" width="400" height="400" />

                    </div>
                    <div class="col-md-7 pt-5 order-2 order-md-1">

                        <h3>4:As per your consent you can visit our centre or we will pick up the battery.</h3>
                        <p style={{ fontSize: "large" }}>As per user comfort we will give all kind of options to reach to us <br />
                            and sell their old
                            battery
                            to us get the right amount.
                        </p>
                    </div>
                </div>

                <div class="row" data-aos="fade-up">
                    <div class="col-md-5">
                        <img src="img/j3.jpg" class="img-fluid" data-aos="flip-right" alt="" width="400" height="400" />
                    </div>
                    <div class="col-md-7 pt-4">
                        <h3>5: Test data with estimated buy back value shared with you</h3>
                        <p style={{ fontSize: "large" }}>User will get every possible details about the buyback price.
                            <br />So that Customer can decide easily
                        </p>
                    </div>
                </div>


                <div class="row" data-aos="fade-down">
                    <div class="col-md-5 order-1 order-md-2">
                        <img src="img/m1.jpg" class="img-fluid" alt="" width="300" height="300" />

                    </div>
                    <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-up">

                        <h3>6:Amount transferred in your account.</h3>
                        <p style={{ fontSize: "large" }}>After getting the buyback deal decided amount will be transferred into
                            your respective bank
                            account
                            <br />Or online bank transaction as per user comfort.
                        </p>
                    </div>
                </div>
            </div>

        </section>
        <div id="team" class="our-team-area area-padding">


<section class="contact-page spad pt-0">
    <section
        id="work-shop"
        className="section-padding"
        style={{ backgroundColor: "rgb(247, 247, 247)", padding: "60px 0px" }}
    >
        <div className="container">
            <div className="header-section text-center">
                <div className="container">
                    <div className="text-center">
                        <div
                            className="wow bounceInDown"
                            data-wow-offset={0}
                            data-wow-delay="0.3s"
                        ></div>
                        <div
                            className="wow bounceInDown"
                            data-wow-offset={0}
                            data-wow-delay="0.6s"
                        >
                            <h2 style={{ color: "#5FCF80" }}>WHY CHOOSE US</h2>
                        </div>
                    </div>
                </div>
                <hr className="bottom-line" />
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6" data-aos="zoom-in">
                    <div
                        className="service-box text-center"
                        style={{
                            background: "#fff",
                            padding: "60px 30px 80px",
                            boxShadow: "0 14px 28px rgba(0,0,0,0.25)"
                        }}
                    >
                        <div
                            className="icon-box"
                            style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
                        >
                            <i className="fa fa-handshake-o color-green" />
                        </div>
                        <div className="icon-text">
                            <h4
                                className="ser-text"
                                style={{
                                    fontSize: 22,
                                    fontFamily: '"Poppins", sans-serif',
                                    fontWeight: "bold",
                                    opacity: "0.7"
                                }}
                            >
                                No risk. 100% satisfaction guaranteed
                            </h4>
                            <br />
                        </div>
                        <div style={{ paddingTop: 25 }}>
                            <div className="site-btn">Know More &gt;</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6" data-aos="zoom-in">
                    <div className="service-box text-center whyUsBox2">
                        <div
                            className="icon-box"
                            style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
                        >
                            <i className="fa fa-usd color-green" />
                        </div>
                        <div className="icon-text" style={{}}>
                            <h4
                                className="ser-text"
                                style={{
                                    fontSize: 22,
                                    fontFamily: '"Poppins", sans-serif',
                                    fontWeight: "bold",
                                    opacity: "0.7"
                                }}
                            >
                                {" "}
                                We pay more then any other buy back service
                            </h4>
                            <br />
                        </div>
                        <div style={{ paddingTop: 40 }}>
                            <div className="site-btn">Know More &gt;</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6" data-aos="zoom-in">
                    <div
                        className="service-box text-center"
                        style={{
                            background: "#fff",
                            padding: "60px 30px 80px",
                            boxShadow: "0 14px 28px rgba(0,0,0,0.25)"
                        }}
                    >
                        <div
                            className="icon-box"
                            style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
                        >
                            <i className="fa fa-hourglass-half color-green" />
                        </div>
                        <div className="icon-text" style={{}}>
                            <h4
                                className="ser-text"
                                style={{
                                    fontSize: 22,
                                    fontFamily: '"Poppins", sans-serif',
                                    fontWeight: "bold",
                                    opacity: "0.7"
                                }}
                            >
                                {" "}
                                We pay in 24 hours after we got the parcel
                            </h4>
                            <br />
                        </div>
                        <div style={{ paddingTop: 25 }}>
                            <div className="site-btn">Know More &gt;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="contact-form spad pb-0" id="hello">
        <div className="section-title text-center">
            <h2 style={{ color: "grey" }}>KEEP IN TOUCH</h2>
            <h5
                style={{ backgroundColor: "tomato", color: "white", fontStyle: "italic" }}
            >
                I'll get back to you as quickly as possible
            </h5>
        </div>
        <form
            className=" container comment-form --contact"
            action="https://mailthis.to/routsabyasachi"
            method="post"
            encType="text/plain"
        >
            <div className="row">
                <label className="col-lg-4">
                    <input type="text" placeholder="Your Name" required />
                </label>
                <label className="col-lg-4">
                    <input type="text" placeholder="Your Email" required />
                </label>
                <label className="col-lg-4">
                    <input type="text" placeholder="Phone No." required />
                </label>
                <label className="col-lg-12">
                    <textarea
                        placeholder="Battery Specifications & Years of Use"
                        defaultValue={""}
                    />
                </label>
                <div className="text-center col-lg-12">
                    <button className="site-btn">SUBMIT</button>
                </div>
            </div>
        </form>
    </div>
</section>
</div>


        </div>
    )
}
export default BuyPage;

// function buyPage() {

//     return (<main id="main">
//         <section id="hero" class="d-flex justify-cntent-center " >
//     <div id="heroCarousel" class="container carousel carousel-fade" data-ride="carousel">

//     {/* style={"align-self: center;" */}


//     </div>
// </section>

        
//         <section class="features">
//             <div class="container">


//                 <div class="section-title">
//                     <h2>Why Buyback ?</h2>

//                 </div>

//                 <div class="row" data-aos="fade-up">
//                     <div class="col-md-5">
//                         <img src="./img/thinking.jpg" class="img-fluid" data-aos="zoom-in" alt="" width="300" height="300" />
//                         {/* <!--				<img src="../liloop-web/img/battery_ups.png"   class="img-fluid" alt="">--> */}
//                     </div>
//                     <div class="col-md-7 pt-4">
//                         {/* <!--			<h3>Why you need to sell your old battery?</h3>--> */}
//                         <p>
//                             <ol style={{ fontSize: "large" }}>
//                                 <li> Disposing lithium batteries in unorganised way will impact the environmental negatively and
//                                     dumping these waste will create huge toxic chemicals in the environment.
//                                 </li>

//                                 <li>Your Dead battery can generate wealth for you. You think, there is no value in your dead
//                                     battery.No you are wrong
//                                     even your dead electric scooter battery is worth Rs.4000 to 6000.Let us figure out the true
//                                     value of your dead battery.
//                                 </li>

//                                 <li>Organised disposal will ensure smooth supply chain of lithium material for your new
//                                     battery in future.
//                                     be a responsible citizen and ensure the organised disposal of your dead battery.
//                                 </li>
//                             </ol>
//                         </p>

//                     </div>
//                 </div>

//                 <div class="row" data-aos="fade-up">
//                     <div class="col-md-5 order-1 order-md-2">
//                         {/* <!--				<img src="img\ev_poster.png" class="img-fluid" data-aos="fade-left" alt="">--> */}
//                         <img src="img/liloop.png" class="img-fluid" data-aos="zoom-in" alt="" />
//                     </div>
//                     <div class="col-md-7 pt-4">
//                         {/* <!--			<h3>Why you need to sell your old battery?</h3>--> */}
//                         <p>
//                             <ol style={{ fontSize: "large" }}>
//                                 <li>We provide the true value of your dead battery after running some preliminary rapid tests at
//                                     our
//                                     centre.
//                                 </li>
//                                 <li> Easy payment within one week of receipt of the dead battery.</li>
//                                 <li>We can provide instant upfront cost reduction for the new battery if you provide the dead
//                                     one to
//                                     us.
//                                 </li>
//                                 <li> We can provide the home pickup of dead battery as well.</li>
//                             </ol>

//                         </p>

//                     </div>
//                 </div>
//             </div>
//         </section>
//         <section class="features">
//             <div class="container">


//                 <div class="section-title">
//                     <h2>HOW IT WORKS </h2>

//                 </div>

//                 <div class="row" data-aos="fade-up">
//                     <div class="col-md-5">
//                         <img src="img/j1.png" class="img-fluid" data-aos="flip-right" alt="" width="300" height="300" />
//                     </div>
//                     <div class="col-md-7 pt-4">
//                         <h3>1:Contact us through email or WhatsApp</h3>
//                         <p style={{ fontSize: "large" }}>Customer can contact us through email, whatsapp, facebook

//                         </p>
//                     </div>
//                 </div>

//                 <div class="row" data-aos="fade-up">
//                     <div class="col-md-5 order-1 order-md-2">
//                         <img src="img/j2.png" class="img-fluid" data-aos="zoom-in" alt="" width="500" height="500" />

//                     </div>
//                     <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="zoom-in">

//                         <h3>2: Fill the form given</h3>
//                         <p style={{ fontSize: "large" }}>
//                             <a href="#hello" style={{ scrollBehavior: "smooth" }}>Click here to check the Form and Fill</a>
//                         </p>


//                     </div>
//                 </div>
//                 <div class="row" data-aos="fade-down">
//                     <div class="col-md-5">
//                         <img src="img/sipu.jpg" class="img-fluid" data-aos="flip-right" alt="" width="300" height="300" />
//                     </div>
//                     <div class="col-md-7 pt-4">
//                         <h3> 3: We will get back to you</h3>
//                         <p style={{ fontSize: "large" }}>We will contact you through email, Phone and various way as per user
//                             Comfort
//                             <br />Or else if Customer need Personal talk you can always come to your nearest office for a
//                             chat.
//                         </p>

//                     </div>
//                 </div>
//                 <div class="row" data-aos="fade-down">
//                     <div class="col-md-5 order-1 order-md-2">
//                         <img src="img/jeeban.jpg" class="img-fluid" data-aos="flip-left" alt="" width="400" height="400" />

//                     </div>
//                     <div class="col-md-7 pt-5 order-2 order-md-1">

//                         <h3>4:As per your consent you can visit our centre or we will pick up the battery.</h3>
//                         <p style={{ fontSize: "large" }}>As per user comfort we will give all kind of options to reach to us <br />
//                             and sell their old
//                             battery
//                             to us get the right amount.
//                         </p>
//                     </div>
//                 </div>

//                 <div class="row" data-aos="fade-up">
//                     <div class="col-md-5">
//                         <img src="img/j3.jpg" class="img-fluid" data-aos="flip-right" alt="" width="400" height="400" />
//                     </div>
//                     <div class="col-md-7 pt-4">
//                         <h3>5: Test data with estimated buy back value shared with you</h3>
//                         <p style={{ fontSize: "large" }}>User will get every possible details about the buyback price.
//                             <br />So that Customer can decide easily
//                         </p>
//                     </div>
//                 </div>


//                 <div class="row" data-aos="fade-down">
//                     <div class="col-md-5 order-1 order-md-2">
//                         <img src="img/m1.jpg" class="img-fluid" alt="" width="300" height="300" />

//                     </div>
//                     <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-up">

//                         <h3>6:Amount transferred in your account.</h3>
//                         <p style={{ fontSize: "large" }}>After getting the buyback deal decided amount will be transferred into
//                             your respective bank
//                             account
//                             <br />Or online bank transaction as per user comfort.
//                         </p>
//                     </div>
//                 </div>
//             </div>

//         </section>
//         <div id="team" class="our-team-area area-padding">


//             <section class="contact-page spad pt-0">
//                 <section
//                     id="work-shop"
//                     className="section-padding"
//                     style={{ backgroundColor: "rgb(247, 247, 247)", padding: "60px 0px" }}
//                 >
//                     <div className="container">
//                         <div className="header-section text-center">
//                             <div className="container">
//                                 <div className="text-center">
//                                     <div
//                                         className="wow bounceInDown"
//                                         data-wow-offset={0}
//                                         data-wow-delay="0.3s"
//                                     ></div>
//                                     <div
//                                         className="wow bounceInDown"
//                                         data-wow-offset={0}
//                                         data-wow-delay="0.6s"
//                                     >
//                                         <h2 style={{ color: "#5FCF80" }}>WHY CHOOSE US</h2>
//                                     </div>
//                                 </div>
//                             </div>
//                             <hr className="bottom-line" />
//                         </div>
//                         <div className="row">
//                             <div className="col-md-4 col-sm-6" data-aos="zoom-in">
//                                 <div
//                                     className="service-box text-center"
//                                     style={{
//                                         background: "#fff",
//                                         padding: "60px 30px 80px",
//                                         boxShadow: "0 14px 28px rgba(0,0,0,0.25)"
//                                     }}
//                                 >
//                                     <div
//                                         className="icon-box"
//                                         style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
//                                     >
//                                         <i className="fa fa-handshake-o color-green" />
//                                     </div>
//                                     <div className="icon-text">
//                                         <h4
//                                             className="ser-text"
//                                             style={{
//                                                 fontSize: 22,
//                                                 fontFamily: '"Poppins", sans-serif',
//                                                 fontWeight: "bold",
//                                                 opacity: "0.7"
//                                             }}
//                                         >
//                                             No risk. 100% satisfaction guaranteed
//                                         </h4>
//                                         <br />
//                                     </div>
//                                     <div style={{ paddingTop: 25 }}>
//                                         <div className="site-btn">Know More &gt;</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4 col-sm-6" data-aos="zoom-in">
//                                 <div className="service-box text-center whyUsBox2">
//                                     <div
//                                         className="icon-box"
//                                         style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
//                                     >
//                                         <i className="fa fa-usd color-green" />
//                                     </div>
//                                     <div className="icon-text" style={{}}>
//                                         <h4
//                                             className="ser-text"
//                                             style={{
//                                                 fontSize: 22,
//                                                 fontFamily: '"Poppins", sans-serif',
//                                                 fontWeight: "bold",
//                                                 opacity: "0.7"
//                                             }}
//                                         >
//                                             {" "}
//                                             We pay more then any other buy back service
//                                         </h4>
//                                         <br />
//                                     </div>
//                                     <div style={{ paddingTop: 40 }}>
//                                         <div className="site-btn">Know More &gt;</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4 col-sm-6" data-aos="zoom-in">
//                                 <div
//                                     className="service-box text-center"
//                                     style={{
//                                         background: "#fff",
//                                         padding: "60px 30px 80px",
//                                         boxShadow: "0 14px 28px rgba(0,0,0,0.25)"
//                                     }}
//                                 >
//                                     <div
//                                         className="icon-box"
//                                         style={{ color: "#5FCF80", fontSize: 65, padding: "20px 0px 0px" }}
//                                     >
//                                         <i className="fa fa-hourglass-half color-green" />
//                                     </div>
//                                     <div className="icon-text" style={{}}>
//                                         <h4
//                                             className="ser-text"
//                                             style={{
//                                                 fontSize: 22,
//                                                 fontFamily: '"Poppins", sans-serif',
//                                                 fontWeight: "bold",
//                                                 opacity: "0.7"
//                                             }}
//                                         >
//                                             {" "}
//                                             We pay in 24 hours after we got the parcel
//                                         </h4>
//                                         <br />
//                                     </div>
//                                     <div style={{ paddingTop: 25 }}>
//                                         <div className="site-btn">Know More &gt;</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <div className="contact-form spad pb-0" id="hello">
//                     <div className="section-title text-center">
//                         <h2 style={{ color: "grey" }}>KEEP IN TOUCH</h2>
//                         <h5
//                             style={{ backgroundColor: "tomato", color: "white", fontStyle: "italic" }}
//                         >
//                             I'll get back to you as quickly as possible
//                         </h5>
//                     </div>
//                     <form
//                         className=" container comment-form --contact"
//                         action="https://mailthis.to/routsabyasachi"
//                         method="post"
//                         encType="text/plain"
//                     >
//                         <div className="row">
//                             <label className="col-lg-4">
//                                 <input type="text" placeholder="Your Name" required />
//                             </label>
//                             <label className="col-lg-4">
//                                 <input type="text" placeholder="Your Email" required />
//                             </label>
//                             <label className="col-lg-4">
//                                 <input type="text" placeholder="Phone No." required />
//                             </label>
//                             <label className="col-lg-12">
//                                 <textarea
//                                     placeholder="Battery Specifications & Years of Use"
//                                     defaultValue={""}
//                                 />
//                             </label>
//                             <div className="text-center col-lg-12">
//                                 <button className="site-btn">SUBMIT</button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </section>
//         </div>
        
//     </main>)
    
// }
// export default buyPage;